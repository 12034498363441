import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import About from "./about";
import Code from "./code";
import Course from "./course";
import Golf from "./golf";
import Random from "./random";

function Main() {
  const [ipaddr, setIpAddr] = useState("");

  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        setIpAddr(response.data.ip);
      })
      .catch((error) => {
        console.error("Error fetching the IP address:", error);
      });
  }, []);

  return (
    <div className="main-section">
      <section>
        <h1 className="typewriter">Hi, my name is Jimmy!</h1>
        <div className="button-container">
          <Link to="/about">
            <button className="main-button">About</button>
          </Link>
          <Link to="/codes">
            <button className="main-button">Codes</button>
          </Link>
          <Link to="/courses">
            <button className="main-button">Courses</button>
          </Link>
          <Link to="/golf">
            <button className="main-button">Golf</button>
          </Link>
          <Link to="/random">
            <button className="main-button">Random</button>
          </Link>
        </div>
        <hr />
        <div>Posts</div>
      </section>
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/codes" element={<Code />} />
        <Route path="/courses" element={<Course />} />
        <Route path="/golf" element={<Golf />} />
        <Route path="/random" element={<Random />} />
      </Routes>
    </Router>
  );
}
