import logo from "./logo.svg";
import "./App.css";
import Mainpage from "./components/mainpage";

function App() {
  return (
    <section>
      <div className="App">{Mainpage()}</div>
    </section>
  );
}

export default App;
