import { useNavigate } from "react-router-dom";
export default function About() {
  const navigate = useNavigate();

  function goToMainPage() {
    navigate("/");
  }

  return (
    <div>
      <button className="back-button">Back</button>
      <div className="about-section">
        <h2>About Me</h2>
        <p>
          I am a passionate developer with experience in cybersecurity and web
          development. I love solving complex problems and building projects
          that make a difference.
        </p>
      </div>
      <section className="about-section">
        <h2>Featured Projects</h2>
        <div className="projects">
          <div className="project">
            <h3>Project Name</h3>
            <p>Brief description of the project...</p>
          </div>
        </div>
      </section>
      <section className="about-section">
        <h2>Contact Me</h2>
        <p>Feel free to reach out to me through the form below:</p>
        <form>
          <input type="email" placeholder="Your Email" />
          <textarea placeholder="Your Message"></textarea>
          <button type="submit">Send Message</button>
        </form>
      </section>
    </div>
  );
}
